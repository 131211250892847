// extracted by mini-css-extract-plugin
export var breadcrumb = "styles-module--breadcrumb--eLvKg";
export var breakme = "styles-module--breakme--pH-W6";
export var content = "styles-module--content--9C1BV";
export var header = "styles-module--header--3oBho";
export var holder = "styles-module--holder--lipKB";
export var howHolder = "styles-module--howHolder--qhQTk";
export var image = "styles-module--image--gUrk0";
export var kaynakca = "styles-module--kaynakca--jVj+-";
export var kidsHolder = "styles-module--kidsHolder--GCHg8";
export var listHolder = "styles-module--listHolder--rD94z";
export var productHolder = "styles-module--productHolder--5zxnZ";
export var rightCoughHolder = "styles-module--rightCoughHolder--euI6S";
export var typesHolder = "styles-module--typesHolder--4Z0QU";
export var whatHolder = "styles-module--whatHolder--KN6p0";
export var whyHolder = "styles-module--whyHolder--upjI6";